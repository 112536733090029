@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Thin.ttf');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraLight.ttf');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Black.ttf');
  font-weight: 900;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-weight: 500;
}